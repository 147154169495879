import React, { Component } from 'react';
import { MenuItem, TextField, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import Moment from 'moment';
import { withRouter } from 'react-router';
import { EventQueries } from '../../../queries/Events';
import { EventMutations } from '../../../mutations/Events';
import MaterialDatePicker from '../../DatePicker';
import MaterialTimePicker from '../../TimePicker';
import InputHelper from '../../../helpers/InputHelper';

const fields = {
  name: { title: 'Event Name', required: true },
  location: { title: 'Event Location', required: true },
  startdate: { title: 'Start Date', required: true },
  starttime: { title: 'Start Time', required: true },
  enddate: { title: 'End Date', required: true },
  endtime: { title: 'End Time', required: true },
  seatsavailable: { title: 'Seats Available', required: true },
  timezonekey: { title: 'Time Zone', required: true },
};

const emptyForm = {};
const emptyErrorSet = {};

Object.keys(fields).forEach(key => {
  emptyForm[key] = '';
  emptyErrorSet[key] = null;
});

const now = new Date();
const nowMoment = Moment(new Date());

emptyForm.startdate = nowMoment.format('MMMM D, YYYY');
emptyForm.enddate = nowMoment.format('MMMM D, YYYY');

const zeroedDate = now.setHours(0, 0, 0, 0);

const dbDate = nowMoment.format('YYYY-MM-DD');

class AddEventForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timezones: [],
      newEvent: { ...emptyForm },
      errors: { ...emptyErrorSet },
      unformattedstartdate: zeroedDate,
      unformattedenddate: zeroedDate,
      unformattedstarttime: null,
      unformattedendtime: null,
      startdatefordb: dbDate,
      enddatefordb: dbDate,
    };
  }

  componentDidMount() {
    this.fetchTimeZones();
  }

  fetchTimeZones = async () => {
    const { client } = this.props;

    try {
      const timezoneList = await client.query({
        query: EventQueries.GetTimezones,
      });

      if (timezoneList && timezoneList !== undefined && timezoneList.data && timezoneList.data !== undefined) {
        this.setState({ timezones: timezoneList.data.getTimezones });
      }
    } catch (error) {
      console.log('Error Fetching Time Zones: ', error);
      this.showError('Error Fetching Time Zones');
    }
  };

  handleChange = event => {
    const { newEvent } = this.state;
    const { name, value } = event.target;
    const eventDetails = { ...newEvent };

    eventDetails[name] = value;

    this.setState({ newEvent: eventDetails });
  };

  handleStartDateChange = date => {
    const { newEvent } = this.state;
    this.setState({ unformattedstartdate: date.setHours(0, 0, 0, 0) });
    const startdate = Moment(date).format('MMMM D, YYYY');

    this.setState({ startdatefordb: Moment(date).format('YYYY-MM-DD') });

    const eventDetails = { ...newEvent };
    eventDetails.startdate = startdate;

    this.setState({ newEvent: eventDetails });
  };

  handleStartTimeChange = time => {
    const { newEvent } = this.state;
    this.setState({ unformattedstarttime: time.setSeconds(0, 0) });

    const starttime = Moment(time).format('HH:mm:ss');

    const eventDetails = { ...newEvent };
    eventDetails.starttime = starttime;

    this.setState({ newEvent: eventDetails });
  };

  handleEndDateChange = date => {
    const { newEvent } = this.state;
    this.setState({ unformattedenddate: date.setHours(0, 0, 0, 0) });
    const enddate = Moment(date).format('MMMM D, YYYY');

    this.setState({ enddatefordb: Moment(date).format('YYYY-MM-DD') });

    const eventDetails = { ...newEvent };
    eventDetails.enddate = enddate;

    this.setState({ newEvent: eventDetails });
  };

  handleEndTimeChange = time => {
    const { newEvent } = this.state;
    this.setState({ unformattedendtime: time.setSeconds(0, 0) });

    const endtime = Moment(time).format('HH:mm:ss');

    const eventDetails = { ...newEvent };
    eventDetails.endtime = endtime;

    this.setState({ newEvent: eventDetails });
  };

  submitForm = async (goNext = false) => {
    const formIsValid = await this.validateForm();

    if (formIsValid) {
      const { client, toggleOverlay } = this.props;

      const { newEvent, startdatefordb, enddatefordb } = this.state;

      const event = {
        name: newEvent.name,
        location: newEvent.location,
        timezonekey: parseInt(newEvent.timezonekey),
        seatsavailable: parseInt(newEvent.seatsavailable),
        startdate: startdatefordb,
        enddate: enddatefordb,
        starttime: newEvent.starttime,
        endtime: newEvent.endtime,
      };

      const result = await client.mutate({
        mutation: EventMutations.CreateEvent,
        variables: { input: event },
      });

      if (result && result !== undefined && result.data && result.data !== undefined && result.data.createEvent && result.data.createEvent !== undefined) {
        const response = result.data.createEvent;
        if (response.wassuccessful === true) {
          this.showSuccess('Event Saved');

          if (goNext === true) {
            const { key } = response;
            const { history } = this.props;
            history.push(`/`);
            history.push(`/events/${key}`);
            toggleOverlay();
          } else {
            this.setState({
              newEvent: { ...emptyForm },
              errors: { ...emptyErrorSet },
              unformattedstartdate: zeroedDate,
              unformattedenddate: zeroedDate,
              unformattedstarttime: null,
              unformattedendtime: null,
              startdatefordb: dbDate,
              enddatefordb: dbDate,
            });
          }
        } else {
          this.showError('Error Saving Event');
        }
      }
    } else {
      this.showError('Error Saving Event');
    }
  };

  validateForm = () =>
    new Promise(resolve => {
      const { newEvent, errors } = this.state;

      let isValid = true;

      Object.keys(fields).forEach(key => {
        const value = newEvent[key];

        if (fields[key].required === true) {
          if (InputHelper.isEmptyOrWhitespace(value)) {
            errors[key] = `${fields[key].title} is required`;
            isValid = false;
          } else {
            errors[key] = null;
          }
        }
      });

      if (errors.startdate === null && errors.enddate === null && errors.endtime === null && errors.starttime === null) {
        console.log('Dates are in need of further validation...');
        const { unformattedstartdate, unformattedenddate, unformattedstarttime, unformattedendtime } = this.state;

        if (unformattedstartdate > unformattedenddate) {
          errors.startdate = `${fields.startdate.title} cannot be after ${fields.enddate.title}`;
          errors.enddate = `${fields.enddate.title} cannot be before ${fields.startdate.title}`;
          isValid = false;
        } else if (unformattedstartdate === unformattedenddate) {
          if (unformattedstarttime >= unformattedendtime) {
            errors.starttime = `${fields.starttime.title} must be before ${fields.endtime.title}`;
            errors.endtime = `${fields.endtime.title} must be after ${fields.starttime.title}`;
            isValid = false;
          }
        }
      }

      const { seatsavailable } = newEvent;

      if (seatsavailable !== null && seatsavailable !== undefined) {
        const str = seatsavailable.toString();
        if (str.indexOf('-') !== -1 || str.indexOf('.') !== -1 || str.indexOf('e') !== -1) {
          errors.seatsavailable = `${fields.seatsavailable.title} must be a positive integer`;
          isValid = false;
        } else {
          errors.seatsavailable = null;
        }
      } else {
        errors.seatsavailable = null;
      }

      this.setState({ errors });

      resolve(isValid);
    });

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  render() {
    const { toggleOverlay, isModal } = this.props;
    const { newEvent, timezones, unformattedstarttime, unformattedendtime, errors } = this.state;

    return (
      <div className="create-event-container">
        <div className="input-row">
          <div className="input-field">
            <TextField
              name="name"
              label={fields.name.title}
              className="flex-1 mb-1"
              value={newEvent.name}
              onChange={this.handleChange}
              error={errors.name && errors.name !== undefined}
              helperText={errors.name}
              inputProps={{ maxLength: 255 }}
              required
            />
          </div>
          <div className="input-field">
            <TextField
              name="location"
              label={fields.location.title}
              className="flex-1 mb-1"
              value={newEvent.location}
              onChange={this.handleChange}
              error={errors.location && errors.location !== undefined}
              helperText={errors.location}
              inputProps={{ maxLength: 255 }}
              required
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-field">
            <TextField
              name="timezonekey"
              select
              label={fields.timezonekey.title}
              className="flex-1 mb-1"
              value={newEvent.timezonekey}
              onChange={this.handleChange}
              error={errors.timezonekey && errors.timezonekey !== undefined}
              helperText={errors.timezonekey}
              required
            >
              {timezones.map(timezone => {
                const gmtOffset = timezone.gmtoffset !== null && timezone.gmtoffset !== undefined ? timezone.gmtoffset : null;
                let timezoneInfo = timezone.codename;

                if (gmtOffset !== null) {
                  try {
                    const absOffset = Math.abs(gmtOffset);
                    let offsetString = `0${absOffset}`;
                    offsetString = offsetString.slice(-2);
                    offsetString += ':00';
                    const offsetOperator = gmtOffset < 0 ? '-' : '+';

                    timezoneInfo += ` (GMT${offsetOperator}${offsetString})`;
                  } catch (error) {
                    console.log('Error parsing offset: ', error);
                  }
                }

                return (
                  <MenuItem key={timezone.timezonekey.toString()} value={timezone.timezonekey}>
                    {timezoneInfo}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div className="input-field">
            <TextField
              name="seatsavailable"
              label={fields.seatsavailable.title}
              className="flex-1 mb-1"
              type="number"
              inputProps={{ min: '0', step: '1' }}
              value={newEvent.seatsavailable}
              onChange={this.handleChange}
              error={errors.seatsavailable && errors.seatsavailable !== undefined}
              helperText={errors.seatsavailable}
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-field">
            <MaterialDatePicker
              onChangeFunc={this.handleStartDateChange}
              value={newEvent.startdate}
              label={fields.startdate.title}
              error={errors.startdate && errors.startdate !== undefined}
              helperText={errors.startdate}
              required
            />
          </div>

          <div className="input-field">
            <MaterialTimePicker
              onChangeFunc={this.handleStartTimeChange}
              value={unformattedstarttime}
              label={fields.starttime.title}
              error={errors.starttime && errors.starttime !== undefined}
              helperText={errors.starttime}
              required
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-field">
            <MaterialDatePicker
              onChangeFunc={this.handleEndDateChange}
              value={newEvent.enddate}
              label={fields.enddate.title}
              error={errors.enddate && errors.enddate !== undefined}
              helperText={errors.enddate}
              required
            />
          </div>

          <div className="input-field">
            <MaterialTimePicker
              onChangeFunc={this.handleEndTimeChange}
              value={unformattedendtime}
              label={fields.endtime.title}
              error={errors.endtime && errors.endtime !== undefined}
              helperText={errors.endtime}
              required
            />
          </div>
        </div>
        <div className="row mt-2 mb-2 mr-2 justify-end">
          {isModal && toggleOverlay ? (
            <div className="cancel-button">
              {
                <Button variant="outlined" color="primary" onClick={() => toggleOverlay()}>
                  Cancel
                </Button>
              }
            </div>
          ) : null}
          <Button variant="outlined" color="primary" onClick={() => this.submitForm(false)}>
            Save & New
          </Button>
          <div className="ml-2">
            <Button variant="contained" color="primary" onClick={() => this.submitForm(true)}>
              Save & View
            </Button>
          </div>
          {/* <Button theme="primary" text="Save" clickHandler={() => this.submitForm(false)} />
            <Button theme="white" text="Next" clickHandler={() => this.submitForm(true)} /> */}
        </div>
      </div>
    );
  }
}

export default withSnackbar(withRouter(withApollo(AddEventForm)));

AddEventForm.propTypes = {
  client: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired,
  isModal: PropTypes.bool,
  toggleOverlay: PropTypes.func,
};
