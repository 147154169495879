/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { MenuItem, TextField, Button, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import Moment from 'moment';
import DoneIcon from '@material-ui/icons/Done';
import { EventQueries } from '../../../queries/Events';
import { EventMutations } from '../../../mutations/Events';
import MaterialDatePicker from '../../DatePicker';
import MaterialTimePicker from '../../TimePicker';
import InputHelper from '../../../helpers/InputHelper';

const states = require('../../../assets/lib/states');

const fields = {
  name: { title: 'Event Name', required: true },
  location: { title: 'Event Location', required: true },
  adjustedstartdate: { title: 'Start Date', required: true },
  adjustedstarttime: { title: 'Start Time', required: true },
  adjustedenddate: { title: 'End Date', required: true },
  adjustedendtime: { title: 'End Time', required: true },
  seatsavailable: { title: 'Seats Available', required: false },
  timezone: {
    timezonekey: { title: 'Time Zone', required: true },
  },
  venue: {
    street: { title: 'Street', required: false },
    phonenumber: { title: 'Phone Number', required: false },
    city: { title: 'City', required: false },
    state: { title: 'State', required: false },
    emailaddress: { title: 'Email Address', required: false },
    country: { title: 'Country', required: false },
    postalcode: { title: 'Postal Code', required: false },
    description: { title: 'Description', required: false },
  },
};

const emptyForm = {};
const emptyErrorSet = {};

Object.keys(fields).forEach(key => {
  if (!fields[key].title || fields[key].title === undefined) {
    emptyForm[key] = {};
    emptyErrorSet[key] = {};

    Object.keys(fields[key]).forEach(key2 => {
      emptyForm[key][key2] = '';
      emptyErrorSet[key][key2] = null;
    });
  } else {
    emptyForm[key] = '';
    emptyErrorSet[key] = null;
  }
});

const now = new Date();
const nowMoment = Moment(new Date());

emptyForm.adjustedstartdate = nowMoment.format('MMMM d, YYYY');
emptyForm.adjustedenddate = nowMoment.format('MMMM d, YYYY');

class EventDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: {
        ...emptyForm,
      },
      errors: { ...emptyErrorSet },
      unformattedstartdate: now.setHours(0, 0, 0, 0),
      unformattedenddate: now.setHours(0, 0, 0, 0),
      unformattedstarttime: null,
      unformattedendtime: null,
      startdatefordb: nowMoment.format('YYYY-MM-DD'),
      enddatefordb: nowMoment.format('YYYY-MM-DD'),
      ispublished: false,
      timezones: [],
    };
  }

  componentDidMount() {
    this.fetchTimeZones();
    this.fetchEventDetails();
  }

  fetchTimeZones = async () => {
    const { client } = this.props;

    try {
      const timezoneList = await client.query({
        query: EventQueries.GetTimezones,
      });

      if (timezoneList && timezoneList !== undefined && timezoneList.data && timezoneList.data !== undefined) {
        this.setState({ timezones: timezoneList.data.getTimezones });
      }
    } catch (error) {
      console.log('Error Fetching Event Detail: ', error);
      this.showError('Error Fetching Event Detail');
    }
  };

  fetchEventDetails = async () => {
    const { client, eventkey } = this.props;

    try {
      const eventDetail = await client.query({
        query: EventQueries.GetEventById,
        variables: { eventkey },
      });

      if (eventDetail && eventDetail.data) {
        this.dataToState(eventDetail.data);
      }
    } catch (error) {
      console.log('Error Fetching Event Detail: ', error);
      this.showError('Error Fetching Event Detail');
    }
  };

  dataToState(data) {
    if (data.getEventById && data.getEventById !== undefined) {
      const details = data.getEventById;

      this.setState({ details, ispublished: details.ispublished });

      const { adjustedstartdatetime, adjustedenddatetime } = details;

      if (adjustedstartdatetime && adjustedstartdatetime !== undefined) {
        const startTimeAsDate = Moment(adjustedstartdatetime)
          .toDate()
          .setSeconds(0, 0);

        const startDateAsDate = Moment(adjustedstartdatetime)
          .toDate()
          .setHours(0, 0, 0, 0);

        const timelessStartDate = Moment(startDateAsDate);
        const startdatefordb = timelessStartDate.format('YYYY-MM-DD');

        this.setState({ unformattedstarttime: startTimeAsDate, unformattedstartdate: startDateAsDate, startdatefordb });
      }

      if (adjustedenddatetime && adjustedenddatetime !== undefined) {
        const endTimeAsDate = Moment(adjustedenddatetime)
          .toDate()
          .setSeconds(0, 0);

        const endDateAsDate = Moment(adjustedenddatetime)
          .toDate()
          .setHours(0, 0, 0, 0);

        const timelessEndDate = Moment(endDateAsDate);
        const enddatefordb = Moment(timelessEndDate).format('YYYY-MM-DD');

        this.setState({ unformattedendtime: endTimeAsDate, unformattedenddate: endDateAsDate, enddatefordb });
      }

      this.setBannerContent();
    } else {
      console.log('Unexpected API response');
      this.showError('Error Parsing Event Detail');
    }
  }

  setBannerContent = () => {
    const { setBannerContent } = this.props;
    const { details, unformattedstartdate, unformattedenddate } = this.state;
    const eventName = details.name;
    const eventLocation = details.location;

    const startMoment = Moment(unformattedstartdate);
    const endMoment = Moment(unformattedenddate);

    const startDateText = startMoment.format('DD');
    const endDateText = endMoment.format('DD');

    let dateText = startDateText;

    if (startDateText !== endDateText) {
      dateText += `-${endDateText}`;
    }

    const startMonth = startMoment.format('MMMM');
    const endMonth = endMoment.format('MMMM');

    let displayDate = `${startMonth} ${dateText}`;

    if (startMonth !== endMonth) {
      displayDate = `${startMonth} ${startDateText} - ${endMonth} ${endDateText}`;
    }

    setBannerContent({
      eventName,
      eventLocation,
      eventDate: displayDate,
    });
  };

  handleChange = event => {
    const { details } = this.state;
    const { name, value } = event.target;
    const eventDetails = { ...details };

    eventDetails[name] = value;

    this.setState({ details: eventDetails });
  };

  handleVenueChange = event => {
    const { details } = this.state;
    const { name, value } = event.target;
    const eventDetails = { ...details };

    eventDetails.venue[name] = value;

    this.setState({ details: eventDetails });
  };

  handleTimezoneChange = event => {
    const { details } = this.state;
    const { name, value } = event.target;
    const eventDetails = { ...details };

    eventDetails.timezone[name] = value;

    this.setState({ details: eventDetails });
  };

  handleStartDateChange = date => {
    const { details } = this.state;
    this.setState({ unformattedstartdate: date.setHours(0, 0, 0, 0) });
    const startdate = Moment(date).format('MMMM D, YYYY');

    this.setState({ startdatefordb: Moment(date).format('YYYY-MM-DD') });

    const eventDetails = { ...details };
    eventDetails.adjustedstartdate = startdate;

    this.setState({ details: eventDetails });
  };

  handleStartTimeChange = time => {
    const { details } = this.state;
    this.setState({ unformattedstarttime: time.setSeconds(0, 0) });

    const starttime = Moment(time).format('HH:mm:ss');

    const eventDetails = { ...details };
    eventDetails.adjustedstarttime = starttime;

    this.setState({ details: eventDetails });
  };

  handleEndDateChange = date => {
    const { details } = this.state;
    this.setState({ unformattedenddate: date.setHours(0, 0, 0, 0) });
    const enddate = Moment(date).format('MMMM D, YYYY');

    this.setState({ enddatefordb: Moment(date).format('YYYY-MM-DD') });

    const eventDetails = { ...details };
    eventDetails.adjustedenddate = enddate;

    this.setState({ details: eventDetails });
  };

  handleEndTimeChange = time => {
    const { details } = this.state;
    this.setState({ unformattedendtime: time.setSeconds(0, 0) });

    const endtime = Moment(time).format('HH:mm:ss');

    const eventDetails = { ...details };
    eventDetails.adjustedendtime = endtime;

    this.setState({ details: eventDetails });
  };

  publishEvent = async () => {
    try {
      const { client, eventkey } = this.props;

      const result = await client.mutate({
        mutation: EventMutations.PublishEvent,
        variables: { eventkey: parseInt(eventkey) },
      });

      if (result && result !== undefined && result.data && result.data !== undefined && result.data.publishEvent && result.data.publishEvent !== undefined) {
        const response = result.data.publishEvent;
        if (response.wassuccessful === true) {
          this.showSuccess('Event Published');
          this.setState({ ispublished: true });
        } else {
          throw new Error('response not successful');
        }
      }
    } catch (error) {
      console.log('Error publishing event: ', error);
      this.showError('Error Publishing Event');
    }
  };

  submitForm = async () => {
    try {
      const formIsValid = await this.validateForm();

      if (formIsValid) {
        const { client, eventkey } = this.props;

        const { details, startdatefordb, enddatefordb } = this.state;

        let seatsavailable = null;

        if (details.seatsavailable) {
          seatsavailable = parseInt(details.seatsavailable);
        }

        const event = {
          eventkey,
          name: details.name,
          location: details.location,
          timezonekey: details.timezone.timezonekey,
          seatsavailable,
          startdate: startdatefordb,
          enddate: enddatefordb,
          starttime: details.adjustedstarttime,
          endtime: details.adjustedendtime,
          description: details.description,
          venue: {
            venuekey: details.venue.venuekey,
            street: details.venue.street,
            city: details.venue.city,
            state: details.venue.state,
            country: details.venue.country,
            postalcode: details.venue.postalcode,
            phonenumber: details.venue.phonenumber,
            emailaddress: details.venue.emailaddress,
            description: details.venue.description,
          },
        };

        const result = await client.mutate({
          mutation: EventMutations.UpdateEvent,
          variables: { input: event },
        });

        if (result && result !== undefined && result.data && result.data !== undefined && result.data.updateEvent && result.data.updateEvent !== undefined) {
          const response = result.data.updateEvent;
          if (response.wassuccessful === true) {
            this.showSuccess('Event Saved');
            this.setBannerContent();
          } else {
            throw new Error('response not successful');
          }
        }
      } else {
        throw new Error('form is not valid');
      }
    } catch (error) {
      console.log('Error saving event: ', error);
      this.showError('Error Saving Event');
    }
  };

  validateForm = () =>
    new Promise(resolve => {
      const { details, errors } = this.state;

      let isValid = true;

      Object.keys(fields).forEach(key => {
        if (!fields[key].title || fields[key].title === undefined) {
          Object.keys(fields[key]).forEach(key2 => {
            const value = details[key][key2];

            if (fields[key][key2].required === true) {
              if (InputHelper.isEmptyOrWhitespace(value)) {
                errors[key] = `${fields[key].title} is required`;
                isValid = false;
              } else {
                errors[key] = null;
              }
            }
          });
        } else {
          const value = details[key];

          if (fields[key].required === true) {
            if (InputHelper.isEmptyOrWhitespace(value)) {
              errors[key] = `${fields[key].title} is required`;
              isValid = false;
            } else {
              errors[key] = null;
            }
          }
        }
      });

      if (errors.adjustedstartdate === null && errors.adjustedenddate === null && errors.adjustedendtime === null && errors.adjustedstarttime === null) {
        this.validateDates(errors);

        if (errors.adjustedstartdate !== null || errors.adjustedenddate !== null || errors.adjustedendtime !== null || errors.adjustedstarttime !== null) {
          isValid = false;
        }
      }

      const { phonenumber, emailaddress } = details.venue;

      if (emailaddress && !InputHelper.isValidEmail(emailaddress)) {
        errors.venue.emailaddress = 'Please enter valid email address';
        isValid = false;
      } else {
        errors.venue.emailaddress = null;
      }
      if (phonenumber && !InputHelper.isNotLetter(phonenumber)) {
        errors.venue.phonenumber = 'No letters allowed in phone number';
        isValid = false;
      } else {
        errors.venue.phonenumber = null;
      }

      const { seatsavailable } = details;

      errors.seatsavailable = this.validateSeatsAvailable(seatsavailable);

      if (!InputHelper.isEmptyOrWhitespace(errors.seatsavailable)) {
        isValid = false;
      }

      this.setState({ errors });

      resolve(isValid);
    });

  validateDates = errors => {
    const { unformattedstartdate, unformattedenddate, unformattedstarttime, unformattedendtime } = this.state;

    if (unformattedstartdate > unformattedenddate) {
      errors.adjustedstartdate = `${fields.adjustedstartdate.title} cannot be after ${fields.adjustedenddate.title}`;
      errors.adjustedenddate = `${fields.adjustedenddate.title} cannot be before ${fields.adjustedstartdate.title}`;
    } else if (unformattedstartdate === unformattedenddate) {
      if (unformattedstarttime >= unformattedendtime) {
        errors.adjustedstarttime = `${fields.adjustedstarttime.title} must be before ${fields.adjustedendtime.title}`;
        errors.adjustedendtime = `${fields.adjustedendtime.title} must be after ${fields.adjustedstarttime.title}`;
      }
    }

    return errors;
  };

  validateSeatsAvailable = seatsavailable => {
    let error = null;

    if (!seatsavailable) {
      error = `${fields.seatsavailable.title} must be a positive integer`;
    }

    if (seatsavailable !== null && seatsavailable !== undefined) {
      const str = seatsavailable.toString();

      if (str.indexOf('-') !== -1 || str.indexOf('.') !== -1 || str.indexOf('e') !== -1) {
        error = `${fields.seatsavailable.title} must be a positive integer`;
      }
    }

    return error;
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  render() {
    const { details, unformattedstartdate, unformattedenddate, unformattedstarttime, unformattedendtime, timezones, errors, ispublished } = this.state;

    const publishedText = (
      <React.Fragment>
        <DoneIcon className="event-published-icon" fontSize="inherit" /> Event Published
      </React.Fragment>
    );

    return (
      <Paper className="paper-base">
        <form className="event-details-card">
          <div className="row section">
            <h5>Event Details</h5>
          </div>
          <div className="row">
            <div className="column flex-1 mr-3">
              <div className="row flex-1">
                <TextField
                  name="name"
                  label={fields.name.title}
                  className="event-input"
                  value={details.name || ''}
                  onChange={this.handleChange}
                  error={errors.name && errors.name !== undefined}
                  helperText={errors.name}
                  inputProps={{ maxLength: 255 }}
                  required
                />
              </div>

              <div className="row flex-1">
                <div className="row flex-1 mr-3 event-form-margin-bottom">
                  <MaterialDatePicker
                    onChangeFunc={this.handleStartDateChange}
                    value={unformattedstartdate || ''}
                    label={fields.adjustedstartdate.title}
                    error={errors.adjustedstartdate && errors.adjustedstartdate !== undefined}
                    helperText={errors.adjustedstartdate}
                    required
                  />
                </div>
                <div className="row flex-1 event-form-margin-bottom">
                  <MaterialTimePicker
                    onChangeFunc={this.handleStartTimeChange}
                    value={unformattedstarttime || ''}
                    label={fields.adjustedstarttime.title}
                    error={errors.adjustedstarttime && errors.adjustedstarttime !== undefined}
                    helperText={errors.adjustedstarttime}
                    required
                  />
                </div>
              </div>

              <div className="row flex-1">
                <div className="row flex-1 mr-3 event-form-margin-bottom">
                  <MaterialDatePicker
                    onChangeFunc={this.handleEndDateChange}
                    value={unformattedenddate || ''}
                    label={fields.adjustedenddate.title}
                    error={errors.adjustedenddate && errors.adjustedenddate !== undefined}
                    helperText={errors.adjustedenddate}
                    required
                  />
                </div>
                <div className="row flex-1 event-form-margin-bottom">
                  <MaterialTimePicker
                    onChangeFunc={this.handleEndTimeChange}
                    value={unformattedendtime || ''}
                    label={fields.adjustedendtime.title}
                    error={errors.adjustedendtime && errors.adjustedendtime !== undefined}
                    helperText={errors.adjustedendtime}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="column flex-1 mr-3">
              <div className="row flex-1">
                <TextField
                  name="location"
                  label={fields.location.title}
                  className="event-input"
                  value={details.location || ''}
                  onChange={this.handleChange}
                  error={errors.location && errors.location !== undefined}
                  helperText={errors.location}
                  inputProps={{ maxLength: 255 }}
                  required
                />
              </div>

              <div className="row flex-1">
                <TextField
                  name="timezonekey"
                  select
                  label={fields.timezone.timezonekey.title}
                  className="event-input-small"
                  value={details.timezone.timezonekey || ''}
                  onChange={this.handleTimezoneChange}
                  error={errors.timezonekey && errors.timezonekey !== undefined}
                  helperText={errors.timezonekey}
                  required
                >
                  {timezones.map(timezone => {
                    const gmtOffset = timezone.gmtoffset !== null && timezone.gmtoffset !== undefined ? timezone.gmtoffset : null;
                    let timezoneInfo = timezone.codename;

                    if (gmtOffset !== null) {
                      try {
                        const absOffset = Math.abs(gmtOffset);
                        let offsetString = `0${absOffset}`;
                        offsetString = offsetString.slice(-2);
                        offsetString += ':00';
                        const offsetOperator = gmtOffset < 0 ? '-' : '+';

                        timezoneInfo += ` (GMT${offsetOperator}${offsetString})`;
                      } catch (error) {
                        console.log('Error parsing offset: ', error);
                      }
                    }

                    return (
                      <MenuItem key={timezone.timezonekey.toString()} value={timezone.timezonekey}>
                        {timezoneInfo}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>

              <div className="row flex-1">
                <TextField
                  name="seatsavailable"
                  label={fields.seatsavailable.title}
                  className="event-input-small"
                  type="number"
                  inputProps={{ min: '0', step: '1' }}
                  value={details.seatsavailable || ''}
                  onChange={this.handleChange}
                  error={errors.seatsavailable && errors.seatsavailable !== undefined}
                  helperText={errors.seatsavailable}
                />
              </div>
            </div>
          </div>

          <div className="row section mt-3">
            <h5 id="venue-location">Venue Location</h5>
          </div>

          <div className="row">
            <div className="column flex-2 mr-3 ml-1">
              <div className="row flex-1">
                <TextField
                  name="street"
                  label={fields.venue.street.title}
                  className="event-input event-form-margin"
                  value={details.venue.street || ''}
                  onChange={this.handleVenueChange}
                  error={errors.venue.street && errors.venue.street !== undefined}
                  helperText={errors.venue.street}
                  inputProps={{ maxLength: 255 }}
                />
                <TextField
                  name="phonenumber"
                  label={fields.venue.phonenumber.title}
                  className="event-input"
                  value={details.venue.phonenumber || ''}
                  onChange={this.handleVenueChange}
                  error={errors.venue.phonenumber && errors.venue.phonenumber !== undefined}
                  helperText={errors.venue.phonenumber}
                  inputProps={{ maxLength: 20 }}
                />
              </div>

              <div className="row flex-1">
                <div className="row flex-1 mr-3">
                  <TextField name="city" label="City" className="event-input event-form-margin" value={details.venue.city || ''} onChange={this.handleVenueChange} />
                  <TextField name="state" select label="State" className="event-input-extra-small" value={details.venue.state || ''} onChange={this.handleVenueChange}>
                    <MenuItem key="n/a" value="">
                      {'N/A'}
                    </MenuItem>
                    {states.map(state => (
                      <MenuItem key={state.abbreviation} value={state.abbreviation || ''}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <TextField
                  name="emailaddress"
                  label="Email Address"
                  className="event-input"
                  value={details.venue.emailaddress || ''}
                  onChange={this.handleVenueChange}
                  error={errors.venue.emailaddress && errors.venue.emailaddress !== undefined}
                  helperText={errors.venue.emailaddress}
                />
              </div>

              <div className="row flex-1">
                <TextField name="country" label="Country" className="event-input event-form-margin" value={details.venue.country || ''} onChange={this.handleVenueChange} />
                <div className="row flex-1">
                  <TextField name="postalcode" label="Postal Code" className="event-input-small" value={details.venue.postalcode || ''} onChange={this.handleVenueChange} />
                </div>
              </div>
            </div>

            <div className="column flex-1 mr-3">
              <label htmlFor="venue_description" className="event-label">
                Venue Description
              </label>
              <textarea id="venue_description" name="description" rows="10" value={details.venue.description || ''} onChange={this.handleVenueChange} />
            </div>
          </div>

          <div className="row section mt-2">
            <h5 id="event-description">Event Description</h5>
          </div>

          <div className="row">
            <div className="column flex-1 mr-3 ml-1">
              <div className="column flex-1">
                <textarea maxLength={500} id="description" name="description" rows="10" value={details.description || ''} onChange={this.handleChange} />
              </div>
              <p className="mt-2 char-limit">Character Limit: 500</p>
            </div>

            <div className="column mr-3">
              <div className="event-btn">
                <Button onClick={this.submitForm} color="primary" variant="outlined">
                  Save
                </Button>
              </div>
              <div className="event-btn">
                {ispublished !== true ? (
                  <Button onClick={this.publishEvent} color="primary" variant="contained">
                    Publish to App
                  </Button>
                ) : (
                  publishedText
                )}{' '}
              </div>
            </div>
          </div>
        </form>
      </Paper>
    );
  }
}

export default withSnackbar(withApollo(EventDetails));

EventDetails.propTypes = {
  client: PropTypes.object.isRequired,
  eventkey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setBannerContent: PropTypes.func.isRequired,
};
