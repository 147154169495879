// eslint-disable no-useless-escape

import AWS from 'aws-sdk';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Storage } from 'aws-amplify';
import { PersonQueries } from '../../queries/Person';
import { SettingQueries } from '../../queries/Setting';
import CognitoHelper from '../../services/CognitoHelper';
import MemberDetailsForm from '../../components/MemberDetails/index';
import StatesHelper from '../../services/States';
import { PersonMutations } from '../../mutations/Person';
import SNSHelper from '../../services/SNSHelper.';
import Subheader from '../../components/Subheader/Subheader';
import LeftDrawer from '../../components/Drawer/LeftDrawer';
import { DrawerIcons } from '../../components/Drawer/DrawerIcons';
import InputHelper from '../../helpers/InputHelper';
import SESHelper from '../../services/SESHelper';
import S3Helper from '../../services/S3Helper';
import SSMHelper from '../../services/SSMHelper';
import DynamoHelper from '../../services/DynamoHelper';

const uuidv4 = require('uuid/v4');

export class MemberDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isValidated: 'pending',
      drawerOpen: false,
      isSaving: false,
      personStatuses: {},
      errors: {},
      memberDetails: {
        about: '',
        firstname: '',
        lastname: '',
        company: '',
        industry: '',
        dateofbirth: '',
        status: '',
        memberstartdate: '',
        memberexpiredate: '',
        passportissuedate: '',
        passportexpirydate: '',
        passportnumber: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postalcode: '',
        phone: '',
        email: '',
        contactpreference: '',
        islefthanded: '',
        showinmobiledirectory: '',
        sub: '',
        deviceid: '',
        bcc1: '',
        bcc2: '',
        user: {
          sub: '',
          deviceid: '',
        },
      },
    };
  }

  componentDidMount() {
    this.getPersonStatuses();
    const { match } = this.props;
    let { personkey } = match.params;

    personkey = parseInt(personkey);
    this.fetchPersonById(personkey);
  }

  getPersonStatuses = async () => {
    const { client } = this.props;
    try {
      const result = await client.query({
        query: PersonQueries.GetPersonStatuses,
      });
      const { getPersonStatuses } = result.data;
      const personStatuses = {};
      getPersonStatuses.forEach(item => {
        personStatuses[item.title] = item.statuskey;
      });
      this.setState({ personStatuses });
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  handleStateChange = event => {
    const { value } = event.target;
    const { memberDetails } = this.state;
    memberDetails.state = StatesHelper.convertToState(value);
    this.setState({ memberDetails });
  };

  handleStatusChange = event => {
    const { value } = event.target;
    const { memberDetails } = this.state;
    memberDetails.status = value;
    this.setState({ memberDetails });
  };

  handleCountryChange = event => {
    const { value } = event.target;
    const { memberDetails } = this.state;
    memberDetails.country = value;
    this.setState({ memberDetails });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { memberDetails } = this.state;
    memberDetails[name] = value;
    this.setState({ memberDetails });
  };

  handlePhoneChange = event => {
    const { memberDetails } = this.state;
    memberDetails.phone = event;
    this.setState({ memberDetails });
  };

  handleCheckbox = event => {
    const { name } = event.target;
    const { memberDetails } = this.state;
    memberDetails[name] = !memberDetails[name];
    this.setState({ memberDetails });
  };

  handleDateChange = (date, fieldname) => {
    const { memberDetails } = this.state;
    memberDetails[fieldname] = date;
    this.setState({ memberDetails: { ...memberDetails } });
    if (date !== 'Invalid Date') {
      const { errors } = this.state;
      errors[fieldname] = null;
      this.setState({ errors: { ...errors } });
    }
  };

  handleDateError = (error, fieldname) => {
    const { errors } = this.state;
    errors[fieldname] = error;
    this.setState({ errors: { ...errors } });
  };

  handleSubmit = async (passwordReset, event) => {
    if (typeof event !== 'undefined' && event != null) {
      event.preventDefault();
    }
    await this.setState({ isSaving: true });

    await this.validateForm();

    const { client } = this.props;
    const { isValidated, memberDetails } = this.state;
    const profilepicture = { ...memberDetails.profilepicture };
    const memberUpdates = { ...memberDetails };
    if (typeof memberUpdates.user !== 'undefined' && memberUpdates.user != null) {
      delete memberUpdates.user__typename;
    }
    delete memberUpdates.profilepicture;
    delete memberUpdates.__typename;
    if (memberUpdates.email) {
      memberUpdates.email = memberUpdates.email.toLowerCase();
    }

    if (passwordReset === true) {
      memberUpdates.user = { deviceid: '', sub: memberUpdates.user.sub };
    } else if (typeof memberUpdates.user !== 'undefined' && memberUpdates.user != null && memberUpdates.user.sub != null) {
      memberUpdates.user = { sub: memberUpdates.user.sub };
    } else {
      memberUpdates.user = {};
    }
    if (isValidated === true) {
      try {
        const result = await client.mutate({
          mutation: PersonMutations.SetMemberDetails,
          variables: { input: memberUpdates },
        });
        if (result && result !== undefined) {
          const { data } = result;

          if (data && data !== undefined && data.setMemberDetails && data.setMemberDetails !== undefined) {
            this.showSuccess('Personal Details Saved Successfully');
            const memberDetails = data.setMemberDetails;
            memberDetails.profilepicture = { ...profilepicture };

            this.setState({
              memberDetails: { ...memberDetails },
              initialDetails: { ...memberDetails },
              loading: false,
              isSaving: false,
            });
          } else {
            this.showError('Error updating user data');
            this.setState({ loading: false, isSaving: false });
          }
        }
      } catch (error) {
        this.showError('Error updating user data');
        this.setState({ loading: false, isSaving: false });
      }
    }
  };

  validateForm = () => {
    const validatedDates = this.validateDates();
    const validatedPhone = this.validatePhone();
    const validatedPerson = this.validatePerson();
    const validatedEmail = this.validateEmail();
    const validatedBccs = this.validateBccs();

    if (validatedPerson && validatedEmail && validatedPhone && validatedDates && validatedBccs) {
      this.setState({ isValidated: true, errors: {} });
    } else {
      this.setState({ isValidated: false, isSaving: false });
      if (!validatedPhone) {
        this.showError('Invalid phone number');
      }
      this.showError('Error saving personal details');
    }
  };

  validateDates = () => {
    const { memberDetails, errors } = this.state;
    if (errors.passportexpirydate || errors.passportissuedate || errors.dateofbirth || errors.memberstartdate || errors.memberexpiredate) {
      return false;
    }
    return true;
  };

  validatePhone = () => {
    const { memberDetails, errors } = this.state;
    if (memberDetails.phone && !isValidPhoneNumber(memberDetails.phone)) {
      const newErrors = { ...errors };
      newErrors.phone = 'Please enter valid phone';
      this.setState({ errors: { ...newErrors } });
      return false;
    }
    if (!memberDetails.phone) {
      const newErrors = { ...errors };
      newErrors.phone = 'Required field';
      this.setState({ errors: { ...newErrors } });
      return false;
    }
    return true;
  };

  validatePerson = () => {
    const { memberDetails } = this.state;
    if (
      memberDetails.firstname === '' ||
      memberDetails.lastname === '' ||
      memberDetails.status === '' ||
      memberDetails.city === '' ||
      memberDetails.country === '' ||
      memberDetails.industry === '' ||
      memberDetails.company === ''
    ) {
      return false;
    }
    return true;
  };

  validateEmail = () => {
    const { memberDetails, errors } = this.state;
    if (memberDetails.email && !InputHelper.isValidEmail(memberDetails.email)) {
      const newErrors = { ...errors };
      newErrors.email = 'Please enter valid email';
      this.setState({ errors: { ...newErrors } });
      return false;
    }
    if (!memberDetails.email) {
      const newErrors = { ...errors };
      newErrors.email = 'Required field';
      this.setState({ errors: { ...newErrors } });
      return false;
    }
    return true;
  };

  validateBccs = () => {
    const { memberDetails, errors } = this.state;
    if (memberDetails.bcc1 && !InputHelper.isValidEmail(memberDetails.bcc1)) {
      const newErrors = { ...errors };
      newErrors.bcc1 = 'Please enter valid email';
      this.setState({ errors: { ...newErrors } });
      return false;
    }
    if (memberDetails.bcc2 && !InputHelper.isValidEmail(memberDetails.bcc2)) {
      const newErrors = { ...errors };
      newErrors.bcc2 = 'Please enter valid email';
      this.setState({ errors: { ...newErrors } });
      return false;
    }
    return true;
  };

  handleCancel = () => {
    const { initialDetails } = this.state;
    this.setState({ memberDetails: { ...initialDetails } });
  };

  sendMobileLink = async () => {
    const { memberDetails, initialDetails } = this.state;

    if (memberDetails && initialDetails) {
      if (initialDetails.phone !== memberDetails.phone || initialDetails.email !== memberDetails.email) {
        alert('Please save your changes before sending the mobile app link');
        return;
      }
    }

    if (!memberDetails.phone || !memberDetails.email == null || !initialDetails.phone || !initialDetails.email) {
      alert('Error: A member must have an email address and phone number.');
    } else {
      this.handleSubmit(false);
      // eslint-disable-next-line no-restricted-globals
      const result = confirm('Are you sure you want to send this user a mobile URL?');

      if (result === true) {
        const createUserResponse = await CognitoHelper.createMobileUser(memberDetails);
        const { client } = this.props;

        try {
          const mobileInstallURL = await client.query({
            query: SettingQueries.GetSettingsByCodenames,
            variables: {
              codenames: ['MobileInstallURL', 'MobileInstallMessage', 'EmailInstallMessage', 'MobileLoginMessage', 'MobileInstallPassword', 'EmailInstallSubject'],
            },
          });

          const uuid = uuidv4();

          await this.createInviteLinks(uuid);

          if (mobileInstallURL && mobileInstallURL.data && mobileInstallURL.data.getSettingsByCodenames) {
            const setting = mobileInstallURL.data.getSettingsByCodenames;

            if (memberDetails.contactpreference && memberDetails.contactpreference === 'text') {
              this.sendSNSUrl(setting, createUserResponse.password, uuid);
            } else if (memberDetails.contactpreference && memberDetails.contactpreference === 'email') {
              this.sendSESUrl(setting, createUserResponse.password, uuid);
            } else if (memberDetails.contactpreference && memberDetails.contactpreference === 'both') {
              this.sendSNSUrl(setting, createUserResponse.password, uuid);
              this.sendSESUrl(setting, createUserResponse.password, uuid);
            } else {
              throw new Error('Error with contact preferences');
            }

            memberDetails.user.sub = createUserResponse.sub;

            this.setState({ memberDetails });
            this.handleSubmit();
          }
        } catch (error) {
          console.log('Error fetching user data: ', error);
          this.setState({ loading: false });
        }
      }
    }
  };

  reSendMobileLink = async () => {
    const { memberDetails } = this.state;
    if (memberDetails.phone == null || memberDetails.phone === '' || memberDetails.email == null || memberDetails.email === '') {
      alert('Error: A member must have an email address and phone number.');
    } else {
      this.handleSubmit(true);
      // eslint-disable-next-line no-restricted-globals
      const result = confirm('Are you sure you want to resend the mobile app link? The user will also receive a new temporary password');
      if (result === true) {
        const { client } = this.props;
        const tempPassword = await CognitoHelper.resetMobilePassword(memberDetails);

        try {
          const mobileInstallURL = await client.query({
            query: SettingQueries.GetSettingsByCodenames,
            variables: {
              codenames: ['MobileInstallURL', 'MobileInstallMessage', 'EmailInstallMessage', 'MobileLoginMessage', 'MobileInstallPassword', 'EmailInstallSubject'],
            },
          });

          const uuid = uuidv4();

          await this.createInviteLinks(uuid);

          if (mobileInstallURL && mobileInstallURL.data && mobileInstallURL.data.getSettingsByCodenames) {
            const setting = mobileInstallURL.data.getSettingsByCodenames;

            if (memberDetails.contactpreference && memberDetails.contactpreference === 'text') {
              this.sendSNSUrl(setting, tempPassword, uuid);
            } else if (memberDetails.contactpreference && memberDetails.contactpreference === 'email') {
              this.sendSESUrl(setting, tempPassword, uuid);
            } else if (memberDetails.contactpreference && memberDetails.contactpreference === 'both') {
              this.sendSNSUrl(setting, tempPassword, uuid);
              this.sendSESUrl(setting, tempPassword, uuid);
            } else {
              throw new Error('Error with contact preferences');
            }
          }
        } catch (error) {
          console.log('Error fetching user data: ', error);
          this.setState({ loading: false });
        }
      }
    }
  };

  sendSESUrl = async (setting, password, uuid) => {
    const { memberDetails } = this.state;
    const subject = setting[3].value;
    let message = setting[4].value;
    const environment = process.env.REACT_APP_STAGE !== 'production' ? `&env=${process.env.REACT_APP_STAGE}` : '';

    message = message.replace(`{${0}}`, `${setting[0].value}?uuid=${uuid}${environment}`);
    message = message.replace(`{${1}}`, memberDetails.email);
    message = message.replace(`{${2}}`, password);

    const sesPayload = {
      To: memberDetails.email,
      Subject: subject,
      Message: message,
    };

    await SESHelper.sendEmail(sesPayload);
  };

  sendSNSUrl = async (setting, password, uuid) => {
    const { memberDetails } = this.state;
    let message = setting[1].value;
    const environment = process.env.REACT_APP_STAGE !== 'production' ? `&env=${process.env.REACT_APP_STAGE}` : '';

    message = message.replace(`{${0}}`, `${setting[0].value}?uuid=${uuid}${environment}`);

    const snsPayload = {
      Message: message,
      PhoneNumber: memberDetails.phone,
    };

    await SNSHelper.sendTextMessage(snsPayload);

    let message2 = setting[2].value;
    message2 = message2.replace(`{${0}}`, memberDetails.email);
    // Delaying second message to ensure message is received next
    setTimeout(async function() {
      const passwordPayload = {
        Message: message2,
        PhoneNumber: memberDetails.phone,
      };

      await SNSHelper.sendTextMessage(passwordPayload);
    }, 2000);
    // Delaying third message to ensure password is received last
    setTimeout(async function() {
      const passwordPayload = {
        Message: password,
        PhoneNumber: memberDetails.phone,
      };

      await SNSHelper.sendTextMessage(passwordPayload);
    }, 4000);
  };

  sendSESPasswordReset = async (sesSubject, message, username) => {
    const sesPayload = {
      To: username,
      Subject: sesSubject,
      Message: message,
    };

    await SESHelper.sendEmail(sesPayload);
  };

  sendSNSPasswordReset = async (message, tempPassword, phoneNumber) => {
    const snsPayload = {
      Message: message,
      PhoneNumber: phoneNumber,
    };

    await SNSHelper.sendTextMessage(snsPayload);

    // Delaying second message to ensure password is received last
    setTimeout(async function() {
      const passwordPayload = {
        Message: tempPassword,
        PhoneNumber: phoneNumber,
      };

      await SNSHelper.sendTextMessage(passwordPayload);
    }, 2000);
  };

  resetMobilePassword = async () => {
    const { memberDetails } = this.state;
    if (memberDetails.phone == null || memberDetails.phone === '' || memberDetails.email == null || memberDetails.email === '') {
      alert('Error: A member must have an email address and phone number.');
    } else {
      this.handleSubmit(true);
      // eslint-disable-next-line no-restricted-globals
      const result = confirm('Are you sure you want to reset this users password?');

      if (result === true) {
        const { client } = this.props;

        try {
          const tempPassword = await CognitoHelper.resetMobilePassword(memberDetails);
          const passwordResetMessage = await client.query({
            query: SettingQueries.GetSettingsByCodenames,
            variables: {
              codenames: ['PasswordResetMessage', 'PasswordResetEmailMessage', 'EmailPasswordResetSubject'],
            },
          });

          if (passwordResetMessage && passwordResetMessage.data && passwordResetMessage.data.getSettingsByCodenames) {
            const setting = passwordResetMessage.data.getSettingsByCodenames;

            let snsMessage = setting[0].value;
            snsMessage = snsMessage.replace(`{${0}}`, memberDetails.email);

            let sesMessage = setting[1].value;
            const sesSubject = setting[2].value;
            sesMessage = sesMessage.replace(`{${0}}`, memberDetails.email);
            sesMessage = sesMessage.replace(`{${1}}`, tempPassword);

            if (memberDetails.contactpreference && memberDetails.contactpreference === 'text') {
              this.sendSNSPasswordReset(snsMessage, tempPassword, memberDetails.phone);
            } else if (memberDetails.contactpreference && memberDetails.contactpreference === 'email') {
              this.sendSESPasswordReset(sesSubject, sesMessage, memberDetails.email);
            } else if (memberDetails.contactpreference && memberDetails.contactpreference === 'both') {
              this.sendSNSPasswordReset(snsMessage, tempPassword, memberDetails.phone);
              this.sendSESPasswordReset(sesSubject, sesMessage, memberDetails.email);
            } else {
              throw new Error('Error with contact preferences');
            }
          }
        } catch (error) {
          console.log('Error fetching user data: ', error);
          this.setState({ loading: false });
        }
      }
    }
  };

  createInviteLinks = async uuid => {
    await this.writeUuidEntry(uuid);
    await this.updatePlistlink();
  };

  writeUuidEntry = async uuid => {
    const iosPSUrl = await this.getPlistPSUrl('ios', false);
    const androidPSUrl = await this.getPlistPSUrl('android', false);
    const writeObject = {
      uuid: { S: uuid },
      androidPSUrl: { S: androidPSUrl },
      iosPSUrl: { S: iosPSUrl },
    };
    const currentStage = process.env.REACT_APP_STAGE === 'local' ? 'development' : process.env.REACT_APP_STAGE;
    const tableName = `uuid-${currentStage}`;

    await DynamoHelper.putItem(writeObject, tableName);
  };

  updatePlistlink = async () => {
    console.log('updatePlistlink');
    const pList = await this.getPlistPSUrl('ios', true);
    let ipaPSUrl = await this.getIPAPSUrl('ios');

    const plistResult = String.fromCharCode(...pList.Body);
    // eslint-disable-next-line prettier/prettier
    ipaPSUrl = ipaPSUrl.replace(/&(?!amp;)/g, `&amp;`);

    // eslint-disable-next-line prettier/prettier
    const updatedPlist = plistResult.replace(/\w*http\w*(.*\<)/g, `${ipaPSUrl}<`);
    console.log(updatedPlist);

    await this.updatePlistFile(updatedPlist);
  };

  getPlistPSUrl = async (platform, isDownload) => {
    const currentStage = process.env.REACT_APP_STAGE === 'local' ? 'development' : process.env.REACT_APP_STAGE;

    if (platform === 'ios') {
      const pSUrl = await Storage.get(`ios/r10${currentStage}.plist`, { bucket: `r10mobile-hosting-${currentStage}`, expires: 604800, download: isDownload });
      return pSUrl;
    }
    if (platform === 'android') {
      const credentials = await SSMHelper.getParametersByPath(`/${currentStage}/s3/`);
      AWS.config.update({ accessKeyId: credentials[`/${currentStage}/s3/accesskey`], secretAccessKey: credentials[`/${currentStage}/s3/secretkey`] });
      const myBucket = `r10mobile-hosting-${currentStage}`;
      const myKey = `public/android/app/release/app-release.apk`;
      const signedUrlExpireSeconds = 604800;
      const s3 = new AWS.S3();
      const pSUrl = s3.getSignedUrl('getObject', {
        Bucket: myBucket,
        Key: myKey,
        Expires: signedUrlExpireSeconds,
      });

      return pSUrl;
    }
    throw new Error('no specified platform');
  };

  getIPAPSUrl = async platform => {
    const currentStage = process.env.REACT_APP_STAGE === 'local' ? 'development' : process.env.REACT_APP_STAGE;
    const currentIPA = process.env.REACT_APP_STAGE === 'production' ? '' : `-${currentStage}`;

    if (platform === 'ios') {
      const credentials = await SSMHelper.getParametersByPath(`/${currentStage}/s3/`);
      AWS.config.update({ accessKeyId: credentials[`/${currentStage}/s3/accesskey`], secretAccessKey: credentials[`/${currentStage}/s3/secretkey`] });
      const myBucket = `r10mobile-hosting-${currentStage}`;
      const myKey = `public/ios/build/mobile-app${currentIPA}.ipa`;
      const signedUrlExpireSeconds = 604800;
      const s3 = new AWS.S3();
      const pSUrl = s3.getSignedUrl('getObject', {
        Bucket: myBucket,
        Key: myKey,
        Expires: signedUrlExpireSeconds,
      });

      return pSUrl;
    }
    if (platform === 'android') {
      const pSUrl = 'TODO';
      return pSUrl;
    }
    throw new Error('no specified platform');
  };

  updatePlistFile = async data => {
    const currentStage = process.env.REACT_APP_STAGE === 'local' ? 'development' : process.env.REACT_APP_STAGE;

    const result = await Storage.put(`ios/r10${currentStage}.plist`, data, { bucket: `r10mobile-hosting-${currentStage}` });
  };

  fetchPersonById = async personkey => {
    const { client } = this.props;
    this.setState({ loading: true });

    try {
      const result = await client.query({
        query: PersonQueries.GetMemberDetails,
        variables: { personkey },
      });

      if (result && result !== undefined) {
        const { data } = result;

        if (data && data.getMemberDetails) {
          const memberDetails = data.getMemberDetails;
          memberDetails.personkey = parseInt(memberDetails.personkey);

          const personalInfo = this.personalInfoReducer(memberDetails);

          this.setState({
            memberDetails: { ...personalInfo },
            initialDetails: { ...personalInfo },
            loading: false,
          });
        } else {
          console.log('Unexpected response format');
          this.setState({ loading: false });
        }
      }
    } catch (error) {
      console.log('Error fetching user data: ', error);
      this.setState({ loading: false });
    }
  };

  personalInfoReducer = memberDetails => {
    const personalInfo = {
      personkey: memberDetails.personkey,
      about: memberDetails.about,
      firstname: memberDetails.firstname,
      lastname: memberDetails.lastname,
      company: memberDetails.company,
      industry: memberDetails.industry,
      dateofbirth: memberDetails.dateofbirth,
      status: memberDetails.status,
      memberstartdate: memberDetails.memberstartdate,
      memberexpiredate: memberDetails.memberexpiredate,
      passportissuedate: memberDetails.passportissuedate,
      passportexpirydate: memberDetails.passportexpirydate,
      passportnumber: memberDetails.passportnumber,
      address: memberDetails.address,
      city: memberDetails.city,
      state: memberDetails.state,
      country: memberDetails.country,
      postalcode: memberDetails.postalcode,
      phone: memberDetails.phone,
      email: memberDetails.email,
      contactpreference: memberDetails.contactpreference,
      islefthanded: memberDetails.islefthanded,
      showinmobiledirectory: memberDetails.showinmobiledirectory,
      deviceid: memberDetails.deviceid,
      userkey: memberDetails.userkey,
      bcc1: memberDetails.bcc1,
      bcc2: memberDetails.bcc2,
      user: { ...memberDetails.user },
      profilepicture: { ...memberDetails.profilepicture },
    };
    return personalInfo;
  };

  fetchProfilePhoto = profilePhoto => {
    this.setState({ profilePhoto });
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  toggleDrawer = () => {
    this.setState(prevState => ({ drawerOpen: !prevState.drawerOpen }));
  };

  getURL = async () => {
    const s3InstallURL = await S3Helper.getInstallUrl();
    console.log(s3InstallURL);
  };

  render() {
    const { loading, memberDetails, isValidated, isSaving, personStatuses, drawerOpen, profilePhoto, errors } = this.state;
    const { match } = this.props;
    let { personkey } = match.params;
    personkey = parseInt(personkey);
    const { Interests, MemberEvents, Notes, PersonalInfo, Assets, VCard } = MemberDetailsForm;

    return (
      <div className="master-container">
        <LeftDrawer toggleDrawer={this.toggleDrawer} open={drawerOpen} children={DrawerIcons.memberDetails} />
        <div className={`master-content ${drawerOpen ? '' : 'master-content-full'}`}>
          <Subheader title="Member Details" />
          <h2 id="personal-info" className="anchor">
            Personal Info
          </h2>
          {memberDetails && (
            <PersonalInfo
              memberDetails={memberDetails}
              isLoading={loading}
              isValidated={isValidated}
              handleDateError={this.handleDateError}
              errors={errors}
              isSaving={isSaving}
              profilePhoto={profilePhoto || memberDetails.profilepicture}
              handleChange={this.handleChange}
              handleCheckbox={this.handleCheckbox}
              handleDateChange={this.handleDateChange}
              handleStateChange={this.handleStateChange}
              handleStatusChange={this.handleStatusChange}
              handleCountryChange={this.handleCountryChange}
              handlePhoneChange={this.handlePhoneChange}
              handleCancel={this.handleCancel}
              handleSubmit={this.handleSubmit}
              sendMobileLink={this.sendMobileLink}
              reSendMobileLink={this.reSendMobileLink}
              resetMobilePassword={this.resetMobilePassword}
              personStatuses={personStatuses}
              fetchProfilePhoto={this.fetchProfilePhoto}
            />
          )}
          <h2 id="notes" className="anchor">
            Notes
          </h2>
          {personkey && <Notes entitykey={personkey} keyType="person" />}
          <h2 id="interests" className="anchor">
            Interests
          </h2>
          {personkey && <Interests personKey={personkey} />}
          <h2 id="events" className="anchor">
            Events
          </h2>
          {personkey && <MemberEvents personkey={personkey} />}
          <h2 id="assets" className="anchor">
            Assets
          </h2>
          {personkey && <Assets personkey={personkey} />}
          <h2 id="vcard" className="anchor">
            VCard
          </h2>
          {personkey && <VCard personkey={personkey} />}
        </div>
      </div>
    );
  }
}

export default withSnackbar(withApollo(withRouter(MemberDetails)));
MemberDetails.propTypes = {
  match: PropTypes.object,
  client: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
};
