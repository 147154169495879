import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ApolloProvider } from 'react-apollo';
import { Auth } from 'aws-amplify';
import { PropTypes } from 'prop-types';
import { UserMutations } from './mutations/User';
import containers from './containers';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ApolloAuth from './services/ApolloAuth';
import AuthHelper from './services/AuthHelper';

class AppWithAuth extends Component {
  state = {
    apolloClient: null,
    refresh: false,
  };

  async componentDidMount() {
    const { history, location } = this.props;

    try {
      await Auth.currentSession().then(res => {
        const client = new ApolloAuth(res.idToken.jwtToken, history);
        const { sub } = res.idToken.payload;

        client.init().then(async result => {
          this.setState({
            apolloClient: result,
            sub,
          });

          const identityId = await AuthHelper.getIdentityId();

          await result.mutate({
            mutation: UserMutations.SetUserIdentityId,
            variables: { sub, identityId },
          });
        });
      });

      if (location.pathname === '/') {
        history.push('./members');
      }
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
        history.push({ path: '/' });
      }
    }
  }

  refreshMembers = () => {
    const { refresh } = this.state;
    this.setState({ refresh: !refresh });
  };

  render() {
    const { AssetDetails, Dashboard, Members, Events, EventDetails, MemberDetails, SupportMessages, BroadcastMessages, News, NewsStory, Polls } = containers;
    const { userHasAuthenticated, history } = this.props;
    const { apolloClient, sub, refresh } = this.state;

    if (apolloClient == null) {
      return '';
    }
    return (
      <ApolloProvider client={apolloClient}>
        <div className="app">
          <Header userHasAuthenticated={userHasAuthenticated} history={history} sub={sub} client={apolloClient} refreshMembers={this.refreshMembers} />
          <div className="main">
            <Switch />
            <Route exact path="/members" component={() => <Members refresh={refresh} refreshMembers={this.refreshMembers} history={history} />} />
            <Route exact path="/events/:eventkey" component={EventDetails} />
            <Route exact path="/events" component={Events} />
            <Route exact path="/memberdetails/:personkey" component={MemberDetails} />
            <Route exact path="/assets/:assetkey" component={AssetDetails} />
            <Route path="/supportmessages/:conversationkey?" component={SupportMessages} />
            <Route path="/broadcastmessages/:conversationkey?" component={BroadcastMessages} />
            <Route exact path="/news" component={News} />
            <Route exact path="/newstory/:id" component={NewsStory} />
            <Route exact path="/polling" component={Polls} />
          </div>
          <Footer />
        </div>
      </ApolloProvider>
    );
  }
}

export default withRouter(AppWithAuth);

AppWithAuth.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  userHasAuthenticated: PropTypes.func.isRequired,
};
