import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { TextField, Chip, Button, Dialog, List, IconButton, MenuItem, DialogTitle } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import AutocompleteItems from './AutocompleteItems';
import InterestFilterProps from './InterestFilterProps';
import { InterestMutations } from '../../../mutations/Interests';
import { FilterQueries } from '../../../queries/Filters';
import { PersonQueries } from '../../../queries/Person';

class InterestAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      selectedItems: [],
      options: [],
      inputValue: '',
      addValue: '',
    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchOptions();
  }

  async fetchData() {
    const { client, filtertype, personKey } = this.props;

    const response = await client.query({
      query: FilterQueries.getMemberInterests,
      variables: { personkey: personKey, filtertype },
    });

    this.setState({
      selectedItems: response.data.getMemberInterests,
    });
  }

  async fetchOptions() {
    const { client, filtertype } = this.props;

    const response = await client.query({
      query: PersonQueries.GetPersonInterests,
      variables: { type: filtertype },
    });
    if (response && response.data && response.data.getInterests) {
      const { getInterests } = response.data;
      this.setState({ options: getInterests });
    }
  }

  handleInputChange = event => {
    const { value } = event.target;
    this.setState({ inputValue: value });
  };

  handleAddChange = event => {
    const { value } = event.target;
    this.setState({ addValue: value });
  };

  handleCreate = async isNew => {
    const { client, personKey, filtertype } = this.props;
    const { inputValue, addValue } = this.state;

    if (!isNew && _.isEmpty(inputValue)) {
      return;
    }
    if (isNew && _.isEmpty(addValue)) {
      return;
    }
    try {
      const response = await client.mutate({
        mutation: InterestMutations.createInterest,
        variables: {
          input: {
            personkey: personKey,
            type: filtertype,
            key: 0,
            value: isNew ? addValue : inputValue,
          },
        },
      });

      const created = response.data.createInterest;

      if (created > 0) {
        const item = {
          type: filtertype,
          key: created,
          value: isNew ? addValue : inputValue,
        };
        this.setState(prevState => ({
          addValue: '',
          inputValue: '',
          isOpen: false,
          selectedItems: [...prevState.selectedItems, item],
        }));
      }
      if (isNew) {
        this.fetchOptions();
      }
    } catch (error) {
      const errorMessage = `${error}`;
      this.showError(errorMessage.split('error:')[1]);
    }
  };

  handleDelete = async item => {
    const { client, personKey } = this.props;
    const { selectedItems } = this.state;
    const index = selectedItems.findIndex(x => x.key === item.key && x.type === item.type);
    if (index === -1) {
      return;
    }

    item.personkey = personKey;
    delete item.__typename;
    const response = await client.mutate({
      mutation: InterestMutations.deleteInterest,
      variables: { input: item },
    });
    if (response.data.deleteInterest) {
      this.setState(prevState => ({
        // eslint-disable-next-line no-shadow
        selectedItems: prevState.selectedItems.filter((_, i) => i !== index),
      }));
    }
  };

  selectItemHandler = async item => {
    const { client, personKey } = this.props;
    const { selectedItems } = this.state;

    const index = selectedItems.findIndex(x => x.key === item.key && x.type === item.type);
    if (index !== -1) {
      return;
    }

    item.personkey = personKey;
    delete item.__typename;
    const response = await client.mutate({
      mutation: InterestMutations.createInterest,
      variables: { input: item },
    });
    if (response.data.createInterest) {
      this.setState(prevState => ({
        inputValue: '',
        isOpen: !prevState.isOpen,
        selectedItems: [...prevState.selectedItems, item],
      }));
    }
  };

  clearAll = async () => {
    const { selectedItems } = this.state;
    if (selectedItems.length === 0) {
      return;
    }
    const { client, personKey, filtertype } = this.props;
    const response = await client.mutate({
      mutation: InterestMutations.deleteAllInterests,
      variables: { personkey: personKey, filtertype },
    });
    if (response.data.deleteAllInterests) {
      this.setState({ isOpen: false, inputValue: '', selectedItems: [] });
    }
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  toggleModal = () => {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  };

  renderInput = () => {
    const { label, placeholder } = this.props;
    const { selectedItems, inputValue, options } = this.state;
    return (
      <div>
        <div className="input-container">
          <TextField
            id="options"
            name="options"
            className="input-root"
            label={label}
            placeholder={placeholder}
            onChange={this.handleInputChange}
            value={inputValue}
            InputProps={{
              startAdornment: (
                <div>
                  {selectedItems.map(item => (
                    <Chip key={item.key} tabIndex={-1} label={item.value} className="downshift-chip" onDelete={() => this.handleDelete(item)} />
                  ))}
                </div>
              ),
            }}
            select
          >
            {options.map(item => (
              <MenuItem value={item.value || ''} key={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </TextField>
          <IconButton color="primary" size="small" onClick={() => this.handleCreate(false)} className="create-btn">
            <AddIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  render() {
    const { selectedItem, modalOpen, addValue } = this.state;

    return (
      <div className="downshift-input">
        {this.renderInput()}
        <div className="button-container">
          <Button onClick={this.toggleModal} color="primary">
            Add New
          </Button>
          <Button onClick={this.clearAll} color="primary">
            Clear All
          </Button>
        </div>
        <Dialog open={modalOpen} onClose={this.toggleModal}>
          <div className="add-tag-modal">
            <DialogTitle>
              <p>Add New Tag</p>
            </DialogTitle>
            <div>
              <TextField value={addValue} onChange={this.handleAddChange} label="New Tag" inputProps={{ maxLength: 15 }} />
              <IconButton color="primary" size="small" onClick={() => this.handleCreate(true)} className="create-btn">
                <AddIcon />
              </IconButton>
            </div>
            <Button onClick={this.toggleModal} color="primary">
              Cancel
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

InterestAutocomplete.propTypes = {
  client: PropTypes.object.isRequired,
  personKey: PropTypes.number.isRequired,
  filtertype: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar(withApollo(InterestAutocomplete));
