// eslint-disable-next-line import/no-extraneous-dependencies
import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';

const sendEmail = async payload =>
  new Promise((resolve, reject) => {
    try {
      const params = {
        Destination: {
          ToAddresses: [payload.To],
        },
        Message: {
          Body: {
            Text: {
              // Charset: 'UTF-8',
              Data: payload.Message,
            },
          },
          Subject: {
            Charset: 'UTF-8',
            Data: payload.Subject || 'Message From R10',
          },
        },
        ConfigurationSetName: `r10-${process.env.REACT_APP_STAGE}-configuration-set`,
        Tags: [
          {
            Name: 'ses-to-email',
            Value: payload.To.replace(/\./g, '_DOT_').replace('@', '_AT_'),
          },
        ],
        Source: 'appmessage@r10org.com',
        ReplyToAddresses: ['noreply@r10org.com'],
      };

      Auth.currentCredentials().then(creds => {
        const sendEmailPromise = new AWS.SES({
          apiVersion: '2010-12-01',
          region: 'us-west-2',
          credentials: creds,
        })
          .sendEmail(params)
          .promise();

        sendEmailPromise
          .then(function(data) {
            console.log(`MessageID is ${data.MessageId}`);
            resolve();
          })
          .catch(function(err) {
            console.error(err, err.stack);
            throw err;
          });
      });
    } catch (error) {
      console.log('Error sending email: ', error);
      reject(error);
    }
  });

const SESHelper = {
  sendEmail,
};

export default SESHelper;