import { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { FilterQueries } from '../../../queries/Filters';

class InterestFilterProps extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { contains } = this.props;
    if (contains !== prevProps.contains && this._isMounted) {
      this.fetchData();
    }
  }

  async fetchData() {
    const { client, contains, filtertype, personKey } = this.props;
    this.setState({ loading: true });

    const data = await client.query({
      query: FilterQueries.getMemberFilter,
      variables: { personkey: personKey, contains, filtertype },
    });

    if (this._isMounted) {
      this.setState({
        loading: false,
        data: data.data.getMemberFilter,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // eslint-disable-next-line react/destructuring-assignment
  render = () => this.props.children({ ...this.state, ...this.props });
}

InterestFilterProps.propTypes = {
  client: PropTypes.object.isRequired,
  personKey: PropTypes.number.isRequired,
  filtertype: PropTypes.string.isRequired,
  contains: PropTypes.string,
  children: PropTypes.any,
};

export default withApollo(InterestFilterProps);
