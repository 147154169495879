/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem, Button, CircularProgress, Paper, Checkbox, FormControlLabel, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import PhotoUploader from './PhotoUploader';
import MaterialKeyboardDatePicker from '../../KeyboardDatePicker';
import StatesHelper from '../../../services/States';
import DocumentPhoto from '../../DocumentPhoto/DocumentPhoto';
import 'react-phone-number-input/style.css';
import { countryToCCA } from '../../../helpers/countryHelper';

export default class PersonalInfo extends Component {
  resetMobilePassword = () => {};

  sendMobileLink = () => {};

  dateFormat = date => {
    const result = new Date(date);
    return result.toLocaleDateString('en-US');
  };

  emailHelperText = () => {
    const { errors, memberDetails } = this.props;
    if (errors.email === 'Required field') {
      if (!memberDetails.email) {
        return errors.email;
      }
      return null;
    }
    return errors.email;
  };

  render() {
    const { memberDetails } = this.props;
    const {
      profilePhoto,
      handleChange,
      handleCancel,
      handleSubmit,
      handleCheckbox,
      handleDateError,
      handleDateChange,
      handleStateChange,
      handlePhoneChange,
      sendMobileLink,
      reSendMobileLink,
      resetMobilePassword,
      isLoading,
      isValidated,
      errors,
      isSaving,
      personStatuses,
      fetchProfilePhoto,
    } = this.props;
    const helperText = this.emailHelperText();

    return (
      <Paper className="paper-base">
        <form className="details-card" autoComplete="off">
          <div className="row section">
            <h5 className="paper-header">Member Details</h5>
          </div>
          {!isLoading ? (
            <div>
              <div className="row">
                <div className="member-details-image">
                  <DocumentPhoto document={profilePhoto} defaultPhoto="person" disableCarousel />
                  {/* <img className="image-selector" source={cameraIcon} alt="change profile pic button" /> */}
                  <PhotoUploader personkey={memberDetails.personkey} fetchProfilePhoto={fetchProfilePhoto} />
                </div>
                <div className="column flex-1">
                  <TextField
                    name="firstname"
                    label="First Name"
                    className="member-input"
                    value={memberDetails.firstname || ''}
                    onChange={handleChange}
                    required
                    error={!isValidated && memberDetails.firstname === ''}
                    helperText={!isValidated && memberDetails.firstname === '' && '* Required field'}
                  />
                  <TextField
                    name="lastname"
                    label="Last Name"
                    className="member-input"
                    value={memberDetails.lastname || ''}
                    onChange={handleChange}
                    required
                    error={!isValidated && memberDetails.lastname === ''}
                    helperText={!isValidated && memberDetails.lastname === '' && '* Required field'}
                  />
                  <TextField
                    name="company"
                    label="Company"
                    className="member-input"
                    value={memberDetails.company || ''}
                    onChange={handleChange}
                    required
                    error={!isValidated && memberDetails.company === ''}
                    helperText={!isValidated && memberDetails.company === '' && '* Required field'}
                  />
                </div>
                <div className="column flex-1">
                  <TextField
                    name="industry"
                    label="Industry"
                    className="member-input"
                    value={memberDetails.industry || ''}
                    onChange={handleChange}
                    required
                    error={!isValidated && memberDetails.industry === ''}
                    helperText={!isValidated && memberDetails.industry === '' && '* Required field'}
                  />
                  <div className="row flex-1">
                    <MaterialKeyboardDatePicker
                      onChangeFunc={handleDateChange}
                      value={memberDetails.dateofbirth}
                      label="Date of Birth"
                      fieldname="dateofbirth"
                      handleDateError={handleDateError}
                      isError={!isValidated && !!errors.dateofbirth}
                      helperText={errors.dateofbirth}
                    />
                  </div>
                </div>
                <div className="column flex-1">
                  <div className="row">
                    <TextField id="status" className="status-select member-input" name="status" label="Status" onChange={handleChange} select required value={memberDetails.status || ''}>
                      {Object.keys(personStatuses).map((personStatus, statusIndex) => (
                        <MenuItem key={statusIndex} value={personStatus || ''}>
                          {personStatus}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="member-input">
                    <MaterialKeyboardDatePicker
                      onChangeFunc={handleDateChange}
                      value={memberDetails.memberstartdate}
                      label="Member Start Date"
                      fieldname="memberstartdate"
                      className="member-input"
                      handleDateError={handleDateError}
                      isError={!isValidated && !!errors.memberstartdate}
                      helperText={errors.memberstartdate}
                    />
                  </div>
                  <div className="row flex-1">
                    <MaterialKeyboardDatePicker
                      onChangeFunc={handleDateChange}
                      value={memberDetails.memberexpiredate}
                      label="Member Expiry Date"
                      fieldname="memberexpiredate"
                      className="member-input"
                      handleDateError={handleDateError}
                      isError={!isValidated && !!errors.memberexpiredate}
                      helperText={errors.memberexpiredate}
                    />
                  </div>
                </div>
              </div>
              <div className="row section">
                <div className="flex flex-3">
                  <div className="column flex-1">
                    <TextField name="address" label="Address" className="member-input" value={memberDetails.address || ''} onChange={handleChange} />
                    <div className="row flex-1 justify-between">
                      <TextField
                        name="city"
                        label="City"
                        className="member-input mr-2 flex-1"
                        value={memberDetails.city || ''}
                        onChange={handleChange}
                        required
                        error={!isValidated && !memberDetails.city}
                        helperText={!isValidated && memberDetails.city === '' && '* Required field'}
                      />
                      <TextField
                        id="state"
                        name="state"
                        label="State"
                        value={StatesHelper.convertToPrefix(memberDetails.state) || ''}
                        onChange={handleStateChange}
                        select
                        className="state-select flex-1 mr-2"
                      >
                        {[...StatesHelper.prefixList].map((item, index) => (
                          <MenuItem value={item || ''} key={`${item} ${index}`}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="row justify-between">
                      <TextField
                        name="country"
                        label="Country"
                        className="member-input country-input flex-1 mr-2"
                        value={memberDetails.country || ''}
                        onChange={handleChange}
                        select
                        required
                        error={!isValidated && !memberDetails.country}
                        helperText={!isValidated && memberDetails.country === '' && '* Required field'}
                      >
                        {Object.keys(countryToCCA).map((item, index) => (
                          <MenuItem value={item || ''} key={`${item}-${index}`}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField name="postalcode" label="Postal Code" className="member-input flex-1" value={memberDetails.postalcode || ''} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="column flex-1">
                    <p className={!isValidated && errors.phone ? 'phone-input-error' : 'phone-input'}>Phone Number *</p>
                    <PhoneInput
                      name="phone"
                      label="Phone"
                      placeholder="Enter Number"
                      className={!isValidated && errors.phone ? 'member-input-error' : 'member-input'}
                      value={memberDetails.phone || ''}
                      onChange={handlePhoneChange}
                    />
                    <TextField
                      name="email"
                      label="Email Address"
                      className="member-input"
                      value={memberDetails.email || ''}
                      onChange={handleChange}
                      error={!isValidated && !!errors.email && !!helperText}
                      helperText={!isValidated && helperText}
                      required
                    />
                    <TextField
                      name="bcc1"
                      label="bcc 1"
                      className="member-input"
                      value={memberDetails.bcc1 || ''}
                      onChange={handleChange}
                      error={!isValidated && !!errors.bcc1}
                      helperText={!isValidated && errors.bcc1}
                    />
                    <TextField
                      name="bcc2"
                      label="bcc 2"
                      className="member-input"
                      value={memberDetails.bcc2 || ''}
                      onChange={handleChange}
                      error={!isValidated && !!errors.bcc2}
                      helperText={!isValidated && errors.bcc2}
                    />
                  </div>
                  <div className="column flex-1">
                    <TextField name="passportnumber" label="Passport Number" className="member-input" value={memberDetails.passportnumber || ''} onChange={handleChange} />
                    <div className="row member-input flex-1">
                      <MaterialKeyboardDatePicker
                        onChangeFunc={handleDateChange}
                        value={memberDetails.passportissuedate}
                        label="Passport Issue Date"
                        fieldname="passportissuedate"
                        handleDateError={handleDateError}
                        isError={!isValidated && !!errors.passportissuedate}
                        helperText={errors.passportissuedate}
                      />
                    </div>
                    <div className="row member-input flex-1">
                      <MaterialKeyboardDatePicker
                        onChangeFunc={handleDateChange}
                        value={memberDetails.passportexpirydate}
                        label="Passport Expiry Date"
                        fieldname="passportexpirydate"
                        handleDateError={handleDateError}
                        isError={!isValidated && !!errors.passportexpirydate}
                        helperText={errors.passportexpirydate}
                      />
                    </div>
                  </div>
                </div>
                <div className="column flex-1 align-center button">
                  <Button color="primary" variant="outlined" onClick={resetMobilePassword}>
                    Reset Mobile Password
                  </Button>
                  {memberDetails.user.sub != null && memberDetails.user.sub !== undefined ? (
                    // <Button color="primary" variant="outlined" onClick={sendMobileLink}>
                    //   Recreate User With New Email Address
                    // </Button>
                    <Button color="primary" variant="outlined" onClick={reSendMobileLink}>
                      Resend Mobile App Link
                    </Button>
                  ) : (
                    <Button color="primary" variant="outlined" onClick={sendMobileLink}>
                      Send Mobile App Link
                    </Button>
                  )}
                  <FormLabel component="legend">Contact Preference</FormLabel>
                  <RadioGroup name="contactpreference" value={memberDetails.contactpreference || 'text'} onChange={handleChange} row className="mb-1">
                    <FormControlLabel value="text" labelPlacement="bottom" label="text" control={<Radio color="primary" />} />
                    <FormControlLabel value="email" labelPlacement="bottom" label="email" control={<Radio color="primary" />} />
                    <FormControlLabel value="both" labelPlacement="bottom" label="both" control={<Radio color="primary" />} />
                  </RadioGroup>
                  <FormControlLabel
                    control={<Checkbox color="primary" name="showinmobiledirectory" checked={memberDetails.showinmobiledirectory} onChange={handleCheckbox} />}
                    label="Show member in mobile app"
                  />
                </div>
              </div>
              <div className="row section">
                <h5 className="paper-header">Bio</h5>
              </div>
              <div className="row">
                <div className="column flex-3">
                  <textarea name="about" id="bio" rows="10" value={memberDetails.about || ''} onChange={handleChange} />
                </div>
                <div className="column flex-1 justify-center align-center button">
                  {!isSaving ? (
                    <Button color="primary" variant="outlined" text="Save" onClick={handleSubmit}>
                      Save
                    </Button>
                  ) : (
                    <Button theme="gray" text="Save">
                      Save
                    </Button>
                  )}
                  <Button color="primary" variant="outlined" text="Reset" onClick={handleCancel}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <CircularProgress />
          )}
        </form>
      </Paper>
    );
  }
}

PersonalInfo.propTypes = {
  memberDetails: PropTypes.object,
  profilePhoto: PropTypes.object,
  handleChange: PropTypes.func,
  handleCancel: PropTypes.func,
  handleCheckbox: PropTypes.func,
  handleDateAccept: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleDateError: PropTypes.func,
  handleCountryChange: PropTypes.func,
  handleStateChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  sendMobileLink: PropTypes.func,
  reSendMobileLink: PropTypes.func,
  resetMobilePassword: PropTypes.func,
  isLoading: PropTypes.bool,
  isValidated: PropTypes.any,
  isSaving: PropTypes.any,
  personStatuses: PropTypes.any,
  fetchProfilePhoto: PropTypes.func,
  errors: PropTypes.object,
};
