import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { CircularProgress, Card, CardActionArea, CardContent, Button, Typography, CardActions } from '@material-ui/core';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from 'notistack';
import { EventMutations } from '../../mutations/Events';
import { EventQueries } from '../../queries/Events';
import Subheader from '../../components/Subheader/Subheader';
import DocumentPhoto from '../../components/DocumentPhoto/DocumentPhoto';

export class Events extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      eventList: [],
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    this.fetchEvents();
  }

  handleError = message => {
    this.setState({ error: message });
    console.log('An Error Occurred: ', message);
  };

  closeConfirmDialog = () => {
    this.setState({ showConfirmationDialog: false, eventToDelete: null });
  };

  dataToState(eventList) {
    if (eventList && eventList !== undefined) {
      const { data } = eventList;

      if (data && data !== undefined && Array.isArray(data.getEvents)) {
        const events = data.getEvents;

        this.setState({ eventList: events, loading: false });
      } else {
        this.handleError('Unexpected list format');
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
      console.log('No Events Returned');
    }
  }

  async fetchEvents(isRefreshing) {
    const { client } = this.props;

    if (!isRefreshing) {
      this.setState({ loading: true });
    }

    try {
      const eventList = await client.query({
        query: EventQueries.GetEvents,
      });

      this.dataToState(eventList);
    } catch (error) {
      this.setState({ loading: false });
      this.handleError(error);
    }
  }

  deleteEvent = async event => {
    const { client } = this.props;
    try {
      const result = await client.mutate({
        mutation: EventMutations.DeleteEvent,
        variables: { eventkey: event.eventkey },
      });
      console.log(result);
      const { wassuccessful } = result.data.deleteEvent;

      if (wassuccessful) {
        this.showSuccess('Event Deleted');
        this.closeConfirmDialog();
        const isRefreshing = true;
        this.fetchEvents(isRefreshing);
      } else {
        this.showError('Error Deleting Event');
      }
    } catch (error) {
      this.showError('Error Deleting Event');
      this.handleError(error);
    }
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  renderEventCards = () => {
    const { eventList, loading, error } = this.state;
    const { history } = this.props;

    if (loading) {
      return (
        <div className="spinner-container">
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <div>Error Loading Events</div>;
    }

    if (eventList && eventList !== undefined && Array.isArray(eventList)) {
      return eventList.map(event => {
        const startMoment = Moment(event.adjustedstartdate);
        const endMoment = Moment(event.adjustedenddate);

        const startDateText = startMoment.format('DD');
        const endDateText = endMoment.format('DD');

        let dateText = startDateText;

        if (startDateText !== endDateText) {
          dateText += `-${endDateText}`;
        }

        const startMonth = startMoment.format('MMMM');
        const endMonth = endMoment.format('MMMM');

        let displayDate = `${startMonth} ${dateText}`;

        if (startMonth !== endMonth) {
          displayDate = `${startMonth} ${startDateText} - ${endMonth} ${endDateText}`;
        }

        const detailLink = `/events/${event.eventkey}`;
        const notesLink = `/events/${event.eventkey}/#notes`;

        return (
          <Card className="wrapping-row-item" key={event.eventkey.toString()}>
            <CardActionArea onClick={() => history.push(detailLink)}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {event.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {event.location}
                  <br />
                  {displayDate}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary" href={detailLink} variant="outlined">
                Details
              </Button>
              <Button size="small" color="primary" href={notesLink} variant="outlined">
                Note
              </Button>
              <Button size="small" color="primary" variant="outlined" onClick={() => this.setState({ showConfirmationDialog: true, eventToDelete: event })}>
                Delete
              </Button>
            </CardActions>
          </Card>
        );
      });
    }
  };

  render() {
    const { showConfirmationDialog, eventToDelete, loading } = this.state;

    const events = this.renderEventCards();
    return (
      <div className="master-container">
        <div className="master-content">
          <Subheader title="Events" />
          {loading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <div className="card-wrap-container">{events && events.length > 0 ? events : <div className="no-events-text">No Events</div>}</div>
          )}

          <Dialog open={showConfirmationDialog} onClose={this.closeConfirmDialog}>
            <DialogTitle>Delete Event</DialogTitle>
            <DialogContent dividers>
              <DialogContentText>Are you sure you want to delete this event?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeConfirmDialog}>Cancel</Button>
              <Button onClick={() => this.deleteEvent(eventToDelete)} color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default withSnackbar(withApollo(Events));

Events.propTypes = {
  client: PropTypes.object,
  enqueueSnackbar: PropTypes.func.isRequired,
};
