// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

const GetMembers = gql`
  query PersonQuery {
    getMembers {
      personkey
      firstname
      lastname
      country
      industry
      company
      city
      personstatus {
        title
      }
      profilepicture {
        documentkey
        name
        accesslevel
        identityid
      }
    }
  }
`;

const GetMemberDetails = gql`
  query GetMemberDetails($personkey: Int) {
    getMemberDetails(personkey: $personkey) {
      personkey
      firstname
      lastname
      company
      city
      state
      country
      about
      industry
      address
      memberstartdate
      memberexpiredate
      showinmobiledirectory
      dateofbirth
      islefthanded
      postalcode
      status
      phone
      email
      contactpreference
      userkey
      passportnumber
      passportissuedate
      passportexpirydate
      gender
      bcc1
      bcc2
      user {
        sub
        deviceid
        vcardemail
        vcardphone
      }
      profilepicture {
        documentkey
        name
        accesslevel
        identityid
      }
      vcardaddress
      vcardcity
      vcardstate
      vcardcountry
      vcardcompany
      vcardpostalcode
      vcardfirstname
      vcardlastname
      vcardassistantname
      vcardassistantemail
      vcardassistantphone
      vcardmobilephone
    }
  }
`;

const GetMembersToMessage = gql`
  query GetMembersToMessage($sub: String!) {
    getMembersToMessage(sub: $sub) {
      personkey
      firstname
      lastname
    }
  }
`;

const GetPersonStatuses = gql`
  query GetPersonStatuses {
    getPersonStatuses {
      title
      statuskey
    }
  }
`;

const GetPersonInterests = gql`
  query GetPersonInterests($type: String!) {
    getInterests(type: $type) {
      key
      value
    }
  }
`;

export const PersonQueries = {
  GetMembers,
  GetMemberDetails,
  GetMembersToMessage,
  GetPersonStatuses,
  GetPersonInterests,
};
