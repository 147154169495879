import * as AWSCognito from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import AWSSDK from 'aws-sdk';
import config from '../config';
import PasswordHelper from './PasswordHelper';

const getCognitoPoolData = () => {
  const poolData = {
    UserPoolId: config.mobile.aws_user_pools_id,
    ClientId: config.mobile.aws_user_pools_web_client_id,
  };

  return poolData;
};

const createMobileUser = async memberDetails =>
  new Promise((resolve, reject) => {
    try {
      const userPool = new AWSCognito.CognitoUserPool(getCognitoPoolData());

      const attributeList = [];

      const dataEmail = {
        Name: 'email',
        Value: memberDetails.email,
      };

      const dataPhoneNumber = {
        Name: 'phone_number',
        Value: `${memberDetails.phone}`,
      };

      const attributeEmail = new AWSCognito.CognitoUserAttribute(dataEmail);
      const attributePhoneNumber = new AWSCognito.CognitoUserAttribute(dataPhoneNumber);

      attributeList.push(attributeEmail);
      attributeList.push(attributePhoneNumber);

      const password = PasswordHelper.createPassword();

      userPool.signUp(memberDetails.email, password, attributeList, null, function(err, result) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }

        Auth.currentCredentials().then(creds => {
          const client = new AWSSDK.CognitoIdentityServiceProvider({
            apiVersion: '2016-04-19',
            region: 'us-west-2',
            credentials: creds,
          });

          client.adminUpdateUserAttributes(
            {
              UserAttributes: [
                {
                  Name: 'phone_number_verified',
                  Value: 'true',
                },
                {
                  Name: 'email_verified',
                  Value: 'true',
                },
              ],
              UserPoolId: config.mobile.aws_user_pools_id,
              Username: memberDetails.email,
            },
            function(err) {
              if (err) {
                console.log(err, err.stack);
              } else {
                console.log('Success!');
              }
            }
          );

          client.adminConfirmSignUp(
            {
              UserPoolId: config.mobile.aws_user_pools_id,
              Username: memberDetails.email,
            },
            function(err) {
              if (err) {
                console.log(err, err.stack);
              } else {
                console.log('Success!');
              }
            }
          );

          const response = { password, sub: result.userSub };
          resolve(response);
        });
      });
    } catch (error) {
      console.log('Error creating user: ', error);
      reject(error);
    }
  });

const resetMobilePassword = async memberDetails =>
  new Promise((resolve, reject) => {
    try {
      Auth.currentCredentials().then(res => {
        const auth = {
          apiVersion: '2016-04-18',
          region: config.admin.aws_project_region,
          credentials: res,
        };

        const cognitoIdentityService = new AWSSDK.CognitoIdentityServiceProvider(auth);

        const userPool = new AWSCognito.CognitoUserPool(getCognitoPoolData());
        const password = PasswordHelper.createPassword();

        const params = {
          Password: password,
          Permanent: true,
          Username: memberDetails.email,
          UserPoolId: userPool.getUserPoolId(),
        };

        cognitoIdentityService.adminSetUserPassword(params, err => {
          if (!err) {
            resolve(password);
          } else {
            reject(err);
          }
        });
      });
    } catch (error) {
      console.log('Error creating user: ', error);
      reject(error);
    }
  });

const CognitoHelper = {
  createMobileUser,
  resetMobilePassword,
};

export default CognitoHelper;
